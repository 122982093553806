import { PurchasePaymentMethod } from "@api/types";
import { ProductCreation } from "@components/CreateUpdateProductForm/CreateUpdateProductForm.types";
import { createContext, useContext } from "react";

export interface NewEventContextProps {
    values: {
        data: {
            name: string;
            startDateTime: string;
            minManAge: number;
            minWomenAge: number;
            description: string;
            address: string;
            tags: string[];
            whatsAppEnabled: boolean;
        };
        onlineSale: {
            hasOnlineSale: boolean;
        };
        paymentMethods: PurchasePaymentMethod[];
        flyers: {
            isVideo: boolean;
            url: string;
            order: number;
        }[];
        products: ProductCreation[];
    };
    setters: {
        setData: React.Dispatch<
            React.SetStateAction<NewEventContextProps["values"]["data"]>
        >;
        setOnlineSale: React.Dispatch<
            React.SetStateAction<NewEventContextProps["values"]["onlineSale"]>
        >;
        setPaymentMethods: React.Dispatch<
            React.SetStateAction<
                NewEventContextProps["values"]["paymentMethods"]
            >
        >;
        setFlyers: React.Dispatch<
            React.SetStateAction<NewEventContextProps["values"]["flyers"]>
        >;
        setProducts: React.Dispatch<
            React.SetStateAction<NewEventContextProps["values"]["products"]>
        >;
    };
    resetValues: () => void;
}

export const NewEventContext = createContext<NewEventContextProps>({
    values: {
        data: {
            name: "",
            startDateTime: "",
            minManAge: 18,
            minWomenAge: 18,
            description: "",
            address: "",
            tags: [],
            whatsAppEnabled: false,
        },
        onlineSale: {
            hasOnlineSale: true,
        },
        paymentMethods: [],
        flyers: [],
        products: [],
    },
    setters: {
        setData: () => {},
        setOnlineSale: () => {},
        setFlyers: () => {},
        setProducts: () => {},
        setPaymentMethods: () => {},
    },
    resetValues: () => {},
});

export const NewEventContextProvider = ({
    children,
    value,
}: {
    children: React.ReactNode;
    value: NewEventContextProps;
}) => {
    return (
        <NewEventContext.Provider value={value}>
            {children}
        </NewEventContext.Provider>
    );
};

export const useNewEventContext = () => useContext(NewEventContext);
