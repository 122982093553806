import { Country, PurchasePaymentMethod } from "@api/types";
import Argentina from "@assets/images/argentina.png";
import Bolivia from "@assets/images/bolivia.png";
import Chile from "@assets/images/chile.png";
import Colombia from "@assets/images/colombia.png";
import CostaRica from "@assets/images/costa_rica.png";
import Ecuador from "@assets/images/ecuador.png";
import Guatemala from "@assets/images/guatemala.png";
import Mexico from "@assets/images/mexico.png";
import Panama from "@assets/images/panama.png";
import Paraguay from "@assets/images/paraguay.png";
import Peru from "@assets/images/peru.png";
import Uruguay from "@assets/images/uruguay.png";

const countriesInfo: Record<
    Country,
    {
        code: string;
        symbol: string;
        image: string;
        name: string;
        emoji: string;
        fiscalIdLabel: string;
        phoneCode: number;
    }
> = {
    [Country.Argentina]: {
        code: "ARS",
        symbol: "$",
        image: Argentina,
        name: "Argentina",
        emoji: "🇦🇷",
        fiscalIdLabel: "DNI",
        phoneCode: 54,
    },
    [Country.Bolivia]: {
        code: "BOB",
        symbol: "Bs. ",
        image: Bolivia,
        name: "Bolivia",
        emoji: "🇧🇴",
        fiscalIdLabel: "CI",
        phoneCode: 591,
    },
    [Country.Chile]: {
        code: "CLP",
        symbol: "$",
        image: Chile,
        name: "Chile",
        emoji: "🇨🇱",
        fiscalIdLabel: "RUT",
        phoneCode: 56,
    },
    [Country.Colombia]: {
        code: "COP",
        symbol: "$",
        image: Colombia,
        name: "Colombia",
        emoji: "🇨🇴",
        fiscalIdLabel: "CC",
        phoneCode: 57,
    },
    [Country.Mexico]: {
        code: "MXN",
        symbol: "$",
        image: Mexico,
        name: "México",
        emoji: "🇲🇽",
        fiscalIdLabel: "INE",
        phoneCode: 52,
    },
    [Country.Peru]: {
        code: "PEN",
        symbol: "S/ ",
        image: Peru,
        name: "Perú",
        emoji: "🇵🇪",
        fiscalIdLabel: "DNI",
        phoneCode: 51,
    },
    [Country.Uruguay]: {
        code: "UYU",
        symbol: "$",
        image: Uruguay,
        name: "Uruguay",
        emoji: "🇺🇾",
        fiscalIdLabel: "CI",
        phoneCode: 598,
    },
    [Country.CostaRica]: {
        code: "CRC",
        symbol: "₡",
        image: CostaRica,
        name: "Costa Rica",
        emoji: "🇨🇷",
        fiscalIdLabel: "Cédula Identidad",
        phoneCode: 506,
    },
    [Country.Ecuador]: {
        code: "USD",
        symbol: "$",
        image: Ecuador,
        name: "Ecuador",
        emoji: "🇪🇨",
        fiscalIdLabel: "Cédula",
        phoneCode: 593,
    },
    [Country.Guatemala]: {
        code: "GTQ",
        symbol: "Q",
        image: Guatemala,
        name: "Guatemala",
        emoji: "🇬🇹",
        fiscalIdLabel: "DPI",
        phoneCode: 502,
    },
    [Country.Panama]: {
        code: "USD",
        symbol: "$",
        image: Panama,
        name: "Panamá",
        emoji: "🇵🇦",
        fiscalIdLabel: "Cédula",
        phoneCode: 507,
    },
    [Country.Paraguay]: {
        code: "PYG",
        symbol: "₲",
        image: Paraguay,
        name: "Paraguay",
        emoji: "🇵🇾",
        fiscalIdLabel: "CI",
        phoneCode: 595,
    },
};

export const getCurrencyCodeByCountry = (country: Country) =>
    countriesInfo[country].code;

export const getCurrencySymbolByCountry = (country: Country) =>
    countriesInfo[country].symbol;

export const getImageByCountry = (country: Country) =>
    countriesInfo[country].image;

export const getNameByCountry = (country: Country) =>
    countriesInfo[country].name;

export const getEmojiByCountry = (country: Country) =>
    countriesInfo[country].emoji;

export const getFiscalIdLabelByCountry = (country: Country) =>
    countriesInfo[country].fiscalIdLabel;

export const getPhoneCodeByCountry = (country: Country) =>
    countriesInfo[country].phoneCode;

export const getPaymentMethodsByCountry = (country: Country) => {
    switch (country) {
        case Country.Argentina:
            return [PurchasePaymentMethod.MercadoPago];
        case Country.Bolivia:
        case Country.Chile:
        case Country.Colombia:
        case Country.CostaRica:
        case Country.Ecuador:
        case Country.Guatemala:
        case Country.Mexico:
        case Country.Panama:
        case Country.Paraguay:
        case Country.Peru:
        case Country.Uruguay:
            return [PurchasePaymentMethod.PayPal];
    }
};
