import AuthRouteWrapper from "@components/AuthRouteWrapper";
import { withLazyLoad } from "@components/LazyLoadContainer/LazyLoadContainer";
import PageWrap from "@components/PageWrap/PageWrap";
import PublicEventLayout from "@components/PublicEventLayout/PublicEventLayout";
import PublicLayout from "@components/PublicLayout";
import SupportLayout from "@components/SupportLayout";
import ApplicationLayout from "@ui/layouts/ApplicationLayout/ApplicationLayout";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import AuthLayout from "../components/AuthLayout/AuthLayout";
import HomePage from "../pages";
import NewEventWrapper from "../pages.deprecated/Organizations/Dashboard/components/NewEvent/NewEventWrapper";

export const routeItems: RouteObject[] = [
    {
        element: <PageWrap />,
        path: "/",
        children: [
            {
                element: <PublicLayout />,
                children: [
                    {
                        index: true,
                        element: <HomePage />,
                    },
                    {
                        path: "delete-my-data",
                        element: withLazyLoad(
                            lazy(
                                () =>
                                    import(
                                        "../pages.deprecated/DeleteMyData/DeleteMyData"
                                    )
                            )
                        ),
                    },
                    {
                        path: "whatsapp-redirect",
                        element: withLazyLoad(
                            lazy(
                                () =>
                                    import(
                                        "../pages.deprecated/WhatsappRedirect/WhatsappRedirect"
                                    )
                            )
                        ),
                    },
                    {
                        element: <SupportLayout />,
                        children: [
                            {
                                path: "tyc",
                                element: withLazyLoad(
                                    lazy(() => import("../pages/tyc"))
                                ),
                            },
                            {
                                path: "terms-and-conditions",
                                element: withLazyLoad(
                                    lazy(
                                        () =>
                                            import(
                                                "../pages.deprecated/Terms/Terms"
                                            )
                                    )
                                ),
                            },
                        ],
                    },
                    {
                        path: "events",
                        element: <PublicEventLayout />,
                        children: [
                            {
                                path: ":eventSlug",
                                element: withLazyLoad(
                                    lazy(() => import("../pages/events/{slug}"))
                                ),
                            },
                            {
                                path: ":eventId",
                                children: [
                                    {
                                        path: "purchase",
                                        children: [
                                            {
                                                path: "products",
                                                element: withLazyLoad(
                                                    lazy(
                                                        () =>
                                                            import(
                                                                "../pages/events/{id}/purchase/products"
                                                            )
                                                    )
                                                ),
                                            },
                                            {
                                                path: "buyer-data",
                                                element: withLazyLoad(
                                                    lazy(
                                                        () =>
                                                            import(
                                                                "../pages/events/{id}/purchase/buyer-data"
                                                            )
                                                    )
                                                ),
                                            },
                                            {
                                                path: "summary",
                                                element: withLazyLoad(
                                                    lazy(
                                                        () =>
                                                            import(
                                                                "../pages/events/{id}/purchase/summary"
                                                            )
                                                    )
                                                ),
                                            },
                                        ],
                                    },
                                    {
                                        path: "purchases",
                                        children: [
                                            {
                                                path: ":purchaseId",
                                                element: withLazyLoad(
                                                    lazy(
                                                        () =>
                                                            import(
                                                                "../pages.deprecated/Events/Client/AfterPurchase/PostPurchase"
                                                            )
                                                    )
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "purchases",
                        children: [
                            {
                                path: ":purchaseId",
                                element: withLazyLoad(
                                    lazy(
                                        () => import("../pages/purchases/{id}")
                                    )
                                ),
                            },
                        ],
                    },
                    {
                        path: "auth",
                        children: [
                            {
                                element: <AuthLayout />,
                                children: [
                                    {
                                        path: "email",
                                        element: withLazyLoad(
                                            lazy(
                                                () =>
                                                    import(
                                                        "../pages/auth/email"
                                                    )
                                            )
                                        ),
                                    },
                                    {
                                        path: "create-password",
                                        element: withLazyLoad(
                                            lazy(
                                                () =>
                                                    import(
                                                        "../pages/auth/create-password"
                                                    )
                                            )
                                        ),
                                    },
                                    {
                                        path: "verify-email",
                                        element: withLazyLoad(
                                            lazy(
                                                () =>
                                                    import(
                                                        "../pages/auth/verify-email"
                                                    )
                                            )
                                        ),
                                    },
                                    {
                                        path: "sign-in",
                                        element: withLazyLoad(
                                            lazy(
                                                () =>
                                                    import(
                                                        "../pages/auth/sign-in"
                                                    )
                                            )
                                        ),
                                    },
                                    {
                                        path: "reset-password",
                                        element: withLazyLoad(
                                            lazy(
                                                () =>
                                                    import(
                                                        "../pages/auth/reset-password"
                                                    )
                                            )
                                        ),
                                    },
                                    {
                                        path: "set-country",
                                        element: withLazyLoad(
                                            lazy(
                                                () =>
                                                    import(
                                                        "../pages/auth/set-country"
                                                    )
                                            )
                                        ),
                                    },
                                ],
                            },
                            {
                                path: "redirect",
                                children: [
                                    {
                                        path: "google",
                                        element: withLazyLoad(
                                            lazy(
                                                () =>
                                                    import(
                                                        "../pages/auth/redirect/google"
                                                    )
                                            )
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                element: <AuthRouteWrapper />,
                children: [
                    {
                        element: <ApplicationLayout />,
                        children: [
                            {
                                path: "organizations",
                                children: [
                                    {
                                        path: "new",
                                        element: withLazyLoad(
                                            lazy(
                                                () =>
                                                    import(
                                                        "../pages.deprecated/Organizations/New/NewOrganization"
                                                    )
                                            )
                                        ),
                                    },
                                    {
                                        path: ":organizationId",
                                        children: [
                                            {
                                                path: "events",
                                                children: [
                                                    {
                                                        index: true,
                                                        element: withLazyLoad(
                                                            lazy(
                                                                () =>
                                                                    import(
                                                                        "../pages/organizations/{id}/events"
                                                                    )
                                                            )
                                                        ),
                                                    },
                                                    {
                                                        path: "new",
                                                        element: (
                                                            <NewEventWrapper />
                                                        ),
                                                        children: [
                                                            {
                                                                path: "online-sale",
                                                                element:
                                                                    withLazyLoad(
                                                                        lazy(
                                                                            () =>
                                                                                import(
                                                                                    "../pages.deprecated/Organizations/Dashboard/components/NewEvent/OnlineSale/NewEventOnlineSale"
                                                                                )
                                                                        )
                                                                    ),
                                                            },
                                                            {
                                                                path: "data",
                                                                element:
                                                                    withLazyLoad(
                                                                        lazy(
                                                                            () =>
                                                                                import(
                                                                                    "../pages.deprecated/Organizations/Dashboard/components/NewEvent/Data/NewEventData"
                                                                                )
                                                                        )
                                                                    ),
                                                            },
                                                            {
                                                                path: "payment-methods",
                                                                element:
                                                                    withLazyLoad(
                                                                        lazy(
                                                                            () =>
                                                                                import(
                                                                                    "../pages.deprecated/Organizations/Dashboard/components/NewEvent/PaymentMethods/NewEventPaymentMethods"
                                                                                )
                                                                        )
                                                                    ),
                                                            },
                                                            {
                                                                path: "flyers",
                                                                element:
                                                                    withLazyLoad(
                                                                        lazy(
                                                                            () =>
                                                                                import(
                                                                                    "../pages.deprecated/Organizations/Dashboard/components/NewEvent/Flyers/NewEventFlyers"
                                                                                )
                                                                        )
                                                                    ),
                                                            },
                                                            {
                                                                path: "products",
                                                                element:
                                                                    withLazyLoad(
                                                                        lazy(
                                                                            () =>
                                                                                import(
                                                                                    "../pages.deprecated/Organizations/Dashboard/components/NewEvent/Products/NewEventProducts"
                                                                                )
                                                                        )
                                                                    ),
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        path: ":eventId",
                                                        children: [
                                                            {
                                                                path: "edit",
                                                                children: [
                                                                    {
                                                                        index: true,
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/edit"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "general",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/edit/general"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "flyers",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/edit/flyers"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "tickets",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/edit/tickets"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "consumptions",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/edit/consumptions"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "tables",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/edit/tables"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "payment-methods",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/edit/payment-methods"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                path: "sales",
                                                                children: [
                                                                    {
                                                                        index: true,
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/sales"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "clients",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/sales/clients"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "by-member",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/sales/by-member"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                path: "courtesies",
                                                                children: [
                                                                    {
                                                                        index: true,
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/courtesies"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "send",
                                                                        element:
                                                                            withLazyLoad(
                                                                                lazy(
                                                                                    () =>
                                                                                        import(
                                                                                            "../pages/organizations/{id}/events/{id}/courtesies/send"
                                                                                        )
                                                                                )
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: "bulk",
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: "product",
                                                                                    element:
                                                                                        withLazyLoad(
                                                                                            lazy(
                                                                                                () =>
                                                                                                    import(
                                                                                                        "../pages/organizations/{id}/events/{id}/courtesies/bulk/product"
                                                                                                    )
                                                                                            )
                                                                                        ),
                                                                                },
                                                                                {
                                                                                    path: "send",
                                                                                    element:
                                                                                        withLazyLoad(
                                                                                            lazy(
                                                                                                () =>
                                                                                                    import(
                                                                                                        "../pages/organizations/{id}/events/{id}/courtesies/bulk/send"
                                                                                                    )
                                                                                            )
                                                                                        ),
                                                                                },
                                                                            ],
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                path: "ticketing",
                                                                children: [
                                                                    {
                                                                        path: "delivery-type",
                                                                        children:
                                                                            [
                                                                                {
                                                                                    index: true,
                                                                                    element:
                                                                                        withLazyLoad(
                                                                                            lazy(
                                                                                                () =>
                                                                                                    import(
                                                                                                        "../pages/organizations/{id}/events/{id}/ticketing/delivery-type"
                                                                                                    )
                                                                                            )
                                                                                        ),
                                                                                },
                                                                                {
                                                                                    path: ":deliveryType",
                                                                                    children:
                                                                                        [
                                                                                            {
                                                                                                path: "send",
                                                                                                element:
                                                                                                    withLazyLoad(
                                                                                                        lazy(
                                                                                                            () =>
                                                                                                                import(
                                                                                                                    "../pages/organizations/{id}/events/{id}/ticketing/delivery-type/{deliveryType}/send"
                                                                                                                )
                                                                                                        )
                                                                                                    ),
                                                                                            },
                                                                                        ],
                                                                                },
                                                                            ],
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                path: "scan-qrs",
                                                element: withLazyLoad(
                                                    lazy(
                                                        () =>
                                                            import(
                                                                "../pages/organizations/{id}/scan-qrs"
                                                            )
                                                    )
                                                ),
                                            },
                                            {
                                                path: "team",
                                                element: withLazyLoad(
                                                    lazy(
                                                        () =>
                                                            import(
                                                                "../pages/organizations/{id}/team"
                                                            )
                                                    )
                                                ),
                                            },
                                            {
                                                path: "external-links",
                                                element: withLazyLoad(
                                                    lazy(
                                                        () =>
                                                            import(
                                                                "../pages/organizations/{id}/external-links"
                                                            )
                                                    )
                                                ),
                                            },
                                            {
                                                path: "premium-credits",
                                                children: [
                                                    {
                                                        index: true,
                                                        element: withLazyLoad(
                                                            lazy(
                                                                () =>
                                                                    import(
                                                                        "../pages/organizations/{id}/premium-credits"
                                                                    )
                                                            )
                                                        ),
                                                    },
                                                    {
                                                        path: "claim",
                                                        element: withLazyLoad(
                                                            lazy(
                                                                () =>
                                                                    import(
                                                                        "../pages/organizations/{id}/premium-credits/claim"
                                                                    )
                                                            )
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "profile",
                                element: withLazyLoad(
                                    lazy(() => import("../pages/profile"))
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "external-links",
                children: [
                    {
                        path: "mercadopago",
                        element: withLazyLoad(
                            lazy(
                                () =>
                                    import(
                                        "../pages/external-links/mercadopago"
                                    )
                            )
                        ),
                    },
                    {
                        path: "uala",
                        element: withLazyLoad(
                            lazy(() => import("../pages/external-links/uala"))
                        ),
                    },
                    {
                        path: "paypal",
                        element: withLazyLoad(
                            lazy(() => import("../pages/external-links/paypal"))
                        ),
                    },
                ],
            },
        ],
    },
];
